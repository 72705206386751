import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Ikke funnet" />
    <h1>Beklager</h1>
    <p>Vi kunne ikke finne siden du lette etter.</p>
  </Layout>
);

export default NotFoundPage;
